import { makeAutoObservable } from 'mobx';
import { TConditionInfo, ICondition, ISanatory } from '../types/global';
import { api } from '../api/instance';

class ConditionsStore {
  isConditionsLoading = false;
  isSanatoriesLoading = false;

  conditionsList: ICondition[] = [];
  sanatoriesList: ISanatory[] = [];

  selected: Record<number, TConditionInfo> = {};

  constructor() {
    makeAutoObservable(this);

    this.getConditionList();
  }

  get isGetButtonDisabled() {
    return !this.conditionsList.every((condition) => !!this.selected[condition.id]);
  }

  getConditionList = async () => {
    try {
      this.setIsConditionsLoading(true);

      const { data } = await api.getConditions();

      this.setConditionsList(data);
    } catch (e) {
      console.error(e);
    } finally {
      this.setIsConditionsLoading(false);
    }
  };

  getSanatoriesList = async () => {
    try {
      this.setIsSanatoriesLoading(true);

      const { data } = await api.postSelections({
        conditions: {
          ...this.selected,
        },
      });

      this.setSanatoriesList(data);
    } catch (e) {
      console.error(e);
    } finally {
      this.setIsSanatoriesLoading(false);
    }
  };

  setIsConditionsLoading = (val: boolean) => {
    this.isConditionsLoading = val;
  };

  setIsSanatoriesLoading = (val: boolean) => {
    this.isSanatoriesLoading = val;
  };

  setConditionsList = (value: ICondition[]) => {
    this.conditionsList = value;
  };

  setSanatoriesList = (value: ISanatory[]) => {
    this.sanatoriesList = value;
  };

  setSelected = (condition: number, choice: TConditionInfo) => {
    this.selected = {
      ...this.selected,
      [condition]: choice,
    };
  };
}

export const conditionsStore = new ConditionsStore();
