import { Row } from 'antd';
import { SanatoryProcedure } from 'components/SanatoryProcedure';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { IProcedure } from 'types/global';

interface IProcedureListProps {
  list: IProcedure[];
}

export const ProcedureList: FC<IProcedureListProps> = observer(({ list }) => {
  return (
    <Row gutter={16}>
      {list.map((procedure) => (
        <SanatoryProcedure key={procedure.name} procedure={procedure} />
      ))}
    </Row>
  );
});
