import axios from 'axios';
import { TConditionInfo } from 'types/global';

export const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const api = {
  getConditions: async () => instance.get('/spa/conditions'),
  postSelections: async (body: { conditions: Record<number, TConditionInfo> }) =>
    instance.post('/spa/selection', body),
};
