import React, { FC } from 'react';
import { ICondition } from '../types/global';
import { Checkbox, Col, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import { conditionsStore } from '../store/ConditionsStore';
import { observer } from 'mobx-react-lite';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ConditionWrap } from './ConditionWrap';

export const enum EConditionType {
  CheckBox,
  RadioButton,
}

interface IConditionProps {
  condition: ICondition;
  conditionType: EConditionType;
}

export const Condition: FC<IConditionProps> = observer(({ condition, conditionType }) => {
  const handleChangeRadioButton = (e: RadioChangeEvent) => {
    conditionsStore.setSelected(condition.id, Number(e.target.value));
  };
  const handleChangeCheckBox = (e: CheckboxValueType[]) => {
    const formedConditionArray = e.map(Number).sort();
    conditionsStore.setSelected(condition.id, formedConditionArray);
  };
  return (
    <ConditionWrap Name={condition.name}>
      {conditionType === EConditionType.RadioButton ? (
        <Radio.Group
          onChange={handleChangeRadioButton}
          value={conditionsStore.selected[condition.id] ?? null}
        >
          <Row gutter={16}>
            {condition.choices.map((choice) => (
              <Col key={choice.id} span={12}>
                <Space direction="vertical" size="small">
                  <Radio value={choice.id}>{choice.name}</Radio>
                  <Typography.Text type="secondary">{choice.description}</Typography.Text>
                </Space>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      ) : (
        <Checkbox.Group onChange={handleChangeCheckBox}>
          <Row gutter={16}>
            {condition.choices.map((choice) => (
              <Col key={choice.id} span={12}>
                <Space direction="vertical" size="small">
                  <Checkbox value={choice.id}>{choice.name}</Checkbox>
                  <Typography.Text type="secondary">{choice.description}</Typography.Text>
                </Space>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      )}
    </ConditionWrap>
  );
});
