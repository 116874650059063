import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import './App.css';
import { ConditionsBlock } from './components/ConditionsBlock';
import { SanatoryBlock } from './components/SanatoryBlock';
import { conditionsStore } from './store/ConditionsStore';
import { Collapse, Space } from 'antd';

const App = observer(() => {
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    if (conditionsStore.sanatoriesList.length) {
      setOpen1(false);
      setOpen2(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [conditionsStore.sanatoriesList]);

  const onCondiBlockChange = () => {
    setOpen1((prev) => !prev);
  };

  const onSanatoryBlockChange = () => {
    setOpen2((prev) => !prev);
  };

  return (
    <Space direction="vertical" size={'middle'} className="main-page">
      <Collapse
        onChange={onCondiBlockChange}
        activeKey={open1 ? [0] : []}
        items={[
          {
            label: 'Симптомы',
            children: <ConditionsBlock />,
          },
        ]}
        collapsible="header"
      />
      <Collapse
        onChange={onSanatoryBlockChange}
        activeKey={open2 ? [0] : []}
        items={[
          {
            label: 'Санатории',
            children: <SanatoryBlock />,
          },
        ]}
        collapsible={!conditionsStore.sanatoriesList?.length ? 'disabled' : 'header'}
      />
    </Space>
  );
});

export default App;
