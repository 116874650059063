import React from 'react';
import { conditionsStore } from '../store/ConditionsStore';
import { Button, Space } from 'antd';
import { Condition, EConditionType } from './Condition';
import { observer } from 'mobx-react-lite';

export const ConditionsBlock = observer(() => {
  return (
    <Space style={{ width: '100%' }} direction="vertical" size={'large'}>
      {conditionsStore.conditionsList.map((condition) => (
        <Condition key={condition.id} condition={condition} conditionType={EConditionType.CheckBox} />
      ))}
      <Button
        disabled={conditionsStore.isGetButtonDisabled}
        loading={conditionsStore.isSanatoriesLoading}
        onClick={conditionsStore.getSanatoriesList}
      >
        Подобрать
      </Button>
    </Space>
  );
});
