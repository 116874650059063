import { Divider, Space } from 'antd';
import { ProcedureList } from 'components/ProcedureList';
import { Sanatory } from 'components/Sanatory';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { conditionsStore } from 'store/ConditionsStore';

export const SanatoryBlock = observer(() => {
  return (
    <Space direction="vertical" size={'large'}>
      {conditionsStore.sanatoriesList.map((sanatory) => (
        <Space direction="vertical" key={sanatory.name}>
          <Divider />
          <Sanatory sanatory={sanatory} />
          <ProcedureList list={sanatory.procedures} />
        </Space>
      ))}
    </Space>
  );
});
