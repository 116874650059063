import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Space } from 'antd';

export interface IConditionWrapProps {
  Name: string;
  children?: ReactNode;
}

export const ConditionWrap: FC<IConditionWrapProps> = observer(({ Name, children }) => {
  return (
    <Space style={{ width: '100%' }} direction="vertical" size={'small'}>
      <Divider orientation="left" style={{ marginBottom: 0 }}>
        {Name}
      </Divider>
      {children}
    </Space>
  );
});
