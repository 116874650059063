import { Col, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { IProcedure } from 'types/global';

interface ISanatoryProcedureProps {
  procedure: IProcedure;
}

export const SanatoryProcedure: FC<ISanatoryProcedureProps> = ({ procedure }) => {
  return (
    <Col span={12}>
      <Space direction="vertical">
        <Space align="baseline" direction="horizontal" size={'large'}>
          <Typography.Title level={5}>{procedure.name}</Typography.Title>
          <Typography.Text style={{ textWrap: 'nowrap' }} code>
            {procedure.price}
          </Typography.Text>
        </Space>
        <Space direction="vertical" size={[0, 0]}>
          <Typography.Paragraph
            style={{ textWrap: 'wrap' }}
            type="secondary"
            ellipsis={{ rows: 4, expandable: true, symbol: 'ещё...' }}
          >
            {procedure.description}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ textWrap: 'wrap' }}
            ellipsis={{ rows: 4, expandable: true, symbol: 'ещё...' }}
          >
            {procedure.personalDescription}
          </Typography.Paragraph>
        </Space>
      </Space>
    </Col>
  );
};
